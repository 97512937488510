<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <span class="modal-card-title">Edit General Details</span>
        <button class="delete" aria-label="close" v-on:click="Close"></button>
      </header>
      <section class="modal-card-body">
        <div class="field">
          <label class="label">Fulfilment Code</label>
          <div class="control">
            <input
              type="text"
              class="input"
              v-model="
                fulfilmentData.Data.products_info.offer_primary_fulfilment_code
              "
            />
          </div>
        </div>
        <div class="field">
          <label class="label">Secondary Fulfilment Code</label>
          <div class="control">
            <input
              type="text"
              class="input"
              v-model="
                fulfilmentData.Data.products_info
                  .offer_secondary_fulfilment_code
              "
            />
          </div>
        </div>
        <div class="field">
          <b-checkbox
            v-model="fulfilmentData.Data.products_info.offer_misc.xmasgift"
            >Xmas Gift</b-checkbox
          >
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary" type="button" @click="updateDetails">
          Update
        </button>
        <button class="button" type="button" @click="Close">Close</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "orderGeneralDetailsDialog",
  props: {
    fulfilment: {
      type: Object,
    },
  },
  data() {
    return {
      APIUrl: process.env.VUE_APP_APIURL,
      fulfilmentData: Object,
    };
  },
  created() {
    if (this.fulfilment !== null) {
      this.fulfilmentData = this.fulfilment;

      this.fulfilmentData.Data.products_info.offer_primary_fulfilment_code = this.fulfilment.Data.products_info.offer_primary_fulfilment_code;
      this.fulfilmentData.Data.products_info.offer_secondary_fulfilment_code = this.fulfilment.Data.products_info.offer_secondary_fulfilment_code;

      if (this.fulfilment.Data.products_info.offer_misc.xmasgift === "1") {
        this.fulfilmentData.Data.products_info.offer_misc.xmasgift = true;
      }
    }
  },
  methods: {
    updateDetails() {
      var that = this;

      var url = this.APIUrl + "/fulfilment/" + that.fulfilment.Id;

      //we store it as 1 or 0, not true or false, so change back to those settings
      //probably could do this nicer with a computed varialbe that is bound to the input
      this.fulfilment.Data.products_info.offer_misc.xmasgift = this.fulfilment
        .Data.products_info.offer_misc.xmasgift
        ? "1"
        : "0";

      this.axios
        .post(url, that.fulfilmentData.Data)
        .then((response) => {
          this.$toast.open("Update has successfully completed");
          this.Close();
        })
        .catch(this.catchError);
    },
    Close: function () {
      this.$emit("close");
    },
  },
};
</script>
