var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal is-active"},[_c('div',{staticClass:"modal-background"}),_c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('span',{staticClass:"modal-card-title"},[_vm._v("Edit General Details")]),_c('button',{staticClass:"delete",attrs:{"aria-label":"close"},on:{"click":_vm.Close}})]),_c('section',{staticClass:"modal-card-body"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Fulfilment Code")]),_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.fulfilmentData.Data.products_info.offer_primary_fulfilment_code
            ),expression:"\n              fulfilmentData.Data.products_info.offer_primary_fulfilment_code\n            "}],staticClass:"input",attrs:{"type":"text"},domProps:{"value":(
              _vm.fulfilmentData.Data.products_info.offer_primary_fulfilment_code
            )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.fulfilmentData.Data.products_info, "offer_primary_fulfilment_code", $event.target.value)}}})])]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Secondary Fulfilment Code")]),_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.fulfilmentData.Data.products_info
                .offer_secondary_fulfilment_code
            ),expression:"\n              fulfilmentData.Data.products_info\n                .offer_secondary_fulfilment_code\n            "}],staticClass:"input",attrs:{"type":"text"},domProps:{"value":(
              _vm.fulfilmentData.Data.products_info
                .offer_secondary_fulfilment_code
            )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.fulfilmentData.Data.products_info
                , "offer_secondary_fulfilment_code", $event.target.value)}}})])]),_c('div',{staticClass:"field"},[_c('b-checkbox',{model:{value:(_vm.fulfilmentData.Data.products_info.offer_misc.xmasgift),callback:function ($$v) {_vm.$set(_vm.fulfilmentData.Data.products_info.offer_misc, "xmasgift", $$v)},expression:"fulfilmentData.Data.products_info.offer_misc.xmasgift"}},[_vm._v("Xmas Gift")])],1)]),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button is-primary",attrs:{"type":"button"},on:{"click":_vm.updateDetails}},[_vm._v(" Update ")]),_c('button',{staticClass:"button",attrs:{"type":"button"},on:{"click":_vm.Close}},[_vm._v("Close")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }